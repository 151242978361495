import {
  CoreAction,
  CoreAudit,
  CoreMultiTransfer,
  CoreWidget,
  CoreWidgetMessage,
  SequenceElement,
  TreeActivity,
  TreeNode, TreeRelationship
} from './core/interface/core.interface';
// tslint:disable-next-line:import-blacklist
import { BehaviorSubject } from 'rxjs/Rx';
import { EventEmitter } from '@angular/core';
import { Map, OrderedMap } from 'immutable';
import { Subject } from 'rxjs/Subject';
import { environment } from '../environments/environment';

export class AppGlobal {

  public static exportUrl: string;
  public static oauthUrl: string;
  public static imageUrl: string;
  public static fileupload: string;
  public static go: string;
  public static authurl: string;
  public static tokenurl: string;
  public static logouturl: string;

  public static treeNodes = OrderedMap<string, TreeNode>();
  public static treeRelationships = OrderedMap<string, TreeRelationship>();
  public static treeActivities = OrderedMap<string, TreeActivity>();
  public static filterCache = [];
  public static filterNodesCache = {};
  public static roleSelection = new BehaviorSubject<TreeNode[]>(undefined);

  public static factSheetTransferIsActive = {};

  public static selectedGroup: string;

  public static loadActions = new EventEmitter();

  public static loading = new BehaviorSubject<boolean>(false);

  public static clearHorizontalStackCache = new EventEmitter<string>();

  public static parentsMap = {};
  public static childrenMap = {};

  public static sourceTreeNode = Map<string, TreeNode>();

  public static byToken = false;
  public static token: string;

  public static instanceId: number;
  public static businessAreaId: number;

  public static nodeTypeMap: any;

  public static preselect = {};

  public static currentDashboardSequenceElement: SequenceElement;

  /* Hide messages */
  public static hideMessages = [];

  /* Selected site */
  public static selectedSite;

  /* Selected */
  public static dataStorageSelected = {};

  /* TRT ids */
  public static trtMap: any;

  /* Scroll */
  public static scrollIntoView: { element: any, callback: Function, animation: any, options: any }[] = [];
  public static scrollIsActive = false;

  /* DNA */
  public static showDNA = false;

  /* Management */
  public static showManagement = false;

  /* Design */
  public static design = '';

  /* Custom export */
  public static customExport: string;

  /* Select nodes dropdown state */
  public static selectedNodeDropdownOpen = {};

  /* Actions per module */
  public static actions = Map<string, CoreAction[]>();

  /* Assign */
  public static assignTransfer = {};
  public static assignTransfers = {};
  public static assignSelected = {};

  /* Drag and drop */
  public static dragEnded = new EventEmitter();

  /* Debug */
  public static debug = false;

  /* Synchronize horizontal */
  public static synchroniseHorizontal = new BehaviorSubject<{ nodeTypes: number, element: number, dates: any, firstDate: string }>(undefined);
  public static synchroniseHorizontalScrolling = new BehaviorSubject<{ target: HTMLElement, left: number, delta: number, clear: boolean, isUserAction: boolean }>(undefined);

  /* Token */
  public static tokenObs = new Subject<string>();
  public static getToken = new Subject<void>();
  public static tokenGetsLoaded = false;
  public static PATH: string;

  /* Dashboard */
  public dashboardHidden = new BehaviorSubject<string[]>([]);
  public dashboardFullscreen = new BehaviorSubject<string>(undefined);

  /* Table button trigger */
  public tableButtonClickedEvent: string;
  public tableButtonClickedTrigger = new EventEmitter<{ id: string, treeNode: TreeNode }>();

  /* Table hide messages */
  public tableHideMessages: CoreWidgetMessage[];

  /* Global filter hierarchy */
  public globalFilterHierarchy = new BehaviorSubject<boolean>(true);

  /* Global filter hierarchy tree nodes */
  public globalFilterHierarchyIds = new BehaviorSubject<string[]>([]);

  /* Global filter hierarchy tree nodes */
  public globalFilterSelectedIds = new BehaviorSubject<any>({});

  public globalFilterSelectedEntries = {};

  /* Global filter clear all as separate trigger */
  public globalFilterClearAll = new Subject();

  /* Global filter chained objects */
  public globalFilterChainedObjects = new BehaviorSubject<boolean>(false);

  /* Global filter direct connection */
  public globalFilterDirectConnection = new BehaviorSubject<boolean>(false);

  /* Global filter chained settings */
  public globalFilterChainedFilters: string[] = [];
  public filterByFieldWidgets: string[] = [];

  /* Global filter use subtract */
  public globalFilterUseSubtract = new BehaviorSubject<string[]>([]);

  /* Selected file */
  public existingFileSelected: TreeNode;

  /* Model cache */
  public modelCache = {};

  /* Widget map */
  public widgetMap: Map<string, CoreWidget>;

  /* Caps to change */
  public capChangeInProgress = false;

  public ganttScrollLeft: number;
}
